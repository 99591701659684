import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
//import firebase from 'firebase';
import { getFirebase } from 'react-redux-firebase';

import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    
    titleBox:{
        width: "100%;",
        textAlign:"center",
        marginTop:"60px",
        marginBottom:"40px",
    },
    
    siteTitle:{
        color: "#595959;",
        opacity: 1,
        textDecoration: "none;",
        WebkitTransition: "opacity 0.2s;",
        transition: "opacity 0.2s;",
        fontWeight: "700;",
        fontSize: "36px",
        lineHeight: "1.25;",
        letterSpacing: "0.08em;",
        margin: 0,
        padding: 0
    },
    siteDescription:{
        color: "#595959;",
        opacity: 0.8,
        fontSize: "16px",
        marginBottom: 0,
        margin: "0 0 1.5em;",
        padding: 0,
        lineHeight:" 1.5;",
    },
});



const App = () => {
    const classes = useStyles();
    const firebase = getFirebase()
    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInSuccessUrl: '/',
        // We will display Google and Facebook as auth providers.
        //provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,      
        signInOptions: [
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
        ], 
        
        callbacks: {
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                firebase.auth().currentUser.getIdToken(true).then(function (token) {

                    document.cookie = "__session=" + token + "; SameSite=Strict;";

                    }).then(function () {

                        window.location.reload();
                        
                    })

                return false;
            },
            uiShown: function() {
              
            }
        }
    };

    return (
        <Container className={classes.MainContainer}>

            <Box className={classes.titleBox}>
                <h1 className={classes.siteTitle}>Tinkers Projects</h1>
                <p className={classes.siteDescription} >Imagine | Develop | Create</p>
            </Box>

            <Box id="firebaseui-auth-container" className={classes.firebaseui}>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
            </Box>
        </Container>
    )
    
}
export default App
  