import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
//import  Firebaseui  from 'firebaseui'

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyDOWRCOs8iX9pmDjB-WOc0yKW2an8FNSEw",
  authDomain: "tinkers-projects.firebaseapp.com",
  projectId: "tinkers-projects",
  storageBucket: "tinkers-projects.appspot.com",
  messagingSenderId: "24816360028",
  appId: "1:24816360028:web:3b6cf81d2299312bfea499",
  measurementId: "G-Z541M0LNNG"
};

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth;

// eslint-disable-next-line no-restricted-globals
if(process.env.REACT_APP_ENV === "development" && process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "development")
{
  db.useEmulator('localhost', 8080);
  auth().useEmulator('http://localhost:9099/', { disableWarnings: true });
}

export default firebase;
export { db, auth };